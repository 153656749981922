import thunk from 'redux-thunk'
import { applyMiddleware, combineReducers, createStore } from "redux";
import { currentUserReducer } from "./currentUser";
import { lessonsReducer } from "./lessons"
import { toastReducer } from "./toast";
import { studentReducer } from "./student";
import { teacherReduser } from "./teachers";
import { groupInfoReducer } from "./groupInfo";
import { teacherSelectReducer } from './teacherSelect';
import { parentReducer } from './parent';
import { filesReducer } from './disk';
import uploadReducer from './upload';
import { paymentNotificationReducer } from './paymentNotification';
import { reviewsReducer } from './reviews';

const reducers = combineReducers({
    currentUser: currentUserReducer,
    lessons: lessonsReducer,
    toast: toastReducer,
    student: studentReducer,
    teachers: teacherReduser,
    groupInfo: groupInfoReducer,
    teacherSelect: teacherSelectReducer,
    parent: parentReducer, 
    files: filesReducer, 
    upload: uploadReducer,
    paymentNotification: paymentNotificationReducer,
    reviews: reviewsReducer
})

export const store = createStore(reducers, applyMiddleware(thunk))