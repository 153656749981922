import $api from "../service/api-service";
import { ShowMessage } from "./actions/toast";

const defaultState = {
    reviews: [],
    page: 1,
    totalPages: 1,
    loading: false,
    teacherParam: {
        teacherId: "",
        approve: "all",
        student: ""
    },
    confirmDeleteId: null,
    confirmApproveId: null,
}

export const reviewsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_REVIEWS_CLEAR":
            return {...state,
                reviews: action.payload.reviews, // Добавляем новые отзывы
                totalPages: action.payload.totalPages,
            }

        case "SET_LOADING": 
            return {...state,
                loading: action.payload
            }
        case "SET_REVIEWS":
            return {
                ...state,
                reviews: [...state.reviews, ...action.payload.reviews], // Добавляем новые отзывы
                totalPages: action.payload.totalPages,
            };
        case "DELETE_REVIEW":
            return {...state,
                reviews: state.reviews.filter(el => el._id !== action.payload)
            }
        case "SET_CONFIRM_DELETE":
            return {...state,
                confirmDeleteId: action.payload
            }
        case "SET_CONFIRM_APPROVE":
            return {...state,
                confirmApproveId: action.payload
            }
        case "SYNC_PROGRESS":
            return {...state,
                reviews: state.reviews.map(el => el._id === action.payload._id ? action.payload : el)
            }
        case "SET_REVIEW_APPROVE":
            return {...state,
                reviews: state.reviews.map(el => el._id === action.payload._id ? { ...el, isReadenByManager: true } : el)
            }
        case "SET_TEACHER_PARAM":
            return {...state,
                teacherParam: { ...state.teacherParam, [action.payload.name]: action.payload.value },
                page: 1 
            }
        case "SET_NEXT_PAGE":
            return {
                ...state,
                page: action.payload, // Обновляем текущую страницу
            };
        default:
            return state
    }
}

export const setLoading = (loading) => ({type: "SET_LOADING", payload: loading});
export const deleteReview = (reviewId) => ({type: "DELETE_REVIEW", payload: reviewId});
export const confirmDelete = (reviewId) => ({type: "SET_CONFIRM_DELETE", payload: reviewId});
export const confirmApprove = (reviewId) => ({type: "SET_CONFIRM_APPROVE", payload: reviewId});
export const syncProgress = (id) => ({type: "SYNC_PROGRESS", payload: id});
export const approveProgress = (response) => ({type: "SET_REVIEW_APPROVE", payload: response});
export const setTeacherParam = (teacherData) => ({type: "SET_TEACHER_PARAM", payload: teacherData});
// Экшн для установки следующей страницы

export const loadReviews = (initPage) => {
    return async (dispatch, getState) => {
        const state = getState();
        const page = initPage ? initPage : state.reviews.page
        const isAdmin = state.currentUser.currentUser.isAdmin
        const userId = state.currentUser.currentUser.userID

        const teacherParam = state.reviews.teacherParam;
        if (!isAdmin) {
            teacherParam.teacherId = userId;
        }

        if (state.reviews.loading) return;

        dispatch(setLoading(true)); // Ставим флаг загрузки

        try {
            const response = await $api.get(`/review/teacher/${teacherParam.teacherId || 'all'}?approve=${teacherParam.approve}&page=${page}&limit=10&student=${teacherParam.student}`);

            dispatch(setReviews({
                reviews: response.data.reviews, 
                totalPages: response.data.totalPages
            }));
            
        } catch (error) {
            dispatch(ShowMessage("Ошибка при загрузке отзывов", "error"));
        } finally {
            dispatch(setLoading(false)); // Завершаем загрузку
        }
    }
};

export const loadNextReviews = (page) => {
    return async (dispatch, getState) => {
        const state = getState();
        const currentPage = state.reviews.page;
        const nextPage = currentPage+1;

        dispatch(loadReviews(page ? page : nextPage))
        dispatch({
            type: "SET_NEXT_PAGE",
            payload: page ? page : nextPage , // Если передана конкретная страница, используем её, иначе увеличиваем текущую
        });
    };
};



export const setReviews = ({ reviews, totalPages }) => {
    return (dispatch, getState) => {
        const existingReviews = getState().reviews.reviews;
        if(getState().reviews.page === 1) {
            return dispatch({
                type: "SET_REVIEWS_CLEAR",
                payload: { reviews: reviews, totalPages }
            });
        }
        const newReviews = reviews.filter(
            newReview => !existingReviews.some(existingReview => existingReview._id === newReview._id)
        );

        dispatch({
            type: "SET_REVIEWS",
            payload: { reviews: newReviews, totalPages }
        });
    };
};

export const handleApproveConfirm = (reviewId) => {
    return (dispatch) => {
        // Логика одобрения отзыва (вы можете заменить её на свою)
        $api.patch('/review/approve/' + reviewId)
            .then(response => {
                if (response.status === 200) {
                    dispatch(approveProgress(response));
                    dispatch(ShowMessage("Отзыв одобрен", "success"));
                }
            })
            .catch(() => {
                dispatch(ShowMessage("Ошибка сервера", "error"));
            });

        dispatch(approveProgress(null));
    }
};

export const deleteHandler = () => {
    return async (dispatch, getState) => {
        const confirmDeleteId = getState().reviews.confirmDeleteId;
        if (!confirmDeleteId) return;
        try {
            const response = await $api.delete('/review/' + confirmDeleteId);
            if (response.status === 205) {
                dispatch(ShowMessage("Отзыв удален", "success"));
                dispatch(deleteReview(confirmDeleteId));
            }
        } catch {
            dispatch(ShowMessage("Ошибка сервера", "error"));
        }

        dispatch(confirmDelete(null));
    } 
};

export const syncHandler = (reviewId) => {
    return async (dispatch) => {
        try {
            const response = await $api.patch('/review/sync-progress/' + reviewId);
            if (response.status === 200) {
                dispatch(syncProgress(response.data))
                dispatch(ShowMessage("Синхрон", "success"));
            }
        } catch {
            dispatch(ShowMessage("Ошибка сервера", "error"));
        }
    }
};
